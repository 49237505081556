import { useEffect } from 'react'

/**
 * 组件挂载完成后执行
 * @param cb
 */
const useMounted = (cb: () => void) => {
  useEffect(() => {
    cb()
  }, [])
}

export default useMounted
