import useMounted from './useMounted'
import { forbidScroll, allowScroll, isBrowser } from 'utils/utils'
require('@eefe/sensors-login/dist/sensors-login.css')
let SensorsLogin: any
export interface useLoginDialogProps {
  visible?: boolean
  lang?: string
  domainPassword?: string
  domainSchool?: string
  config?: {
    channel?: '' // 渠道字段，
    requestBtn?: '' // 按钮名
    formpage_source?: '' // 注册表单类型，可选值：视频、demo、文档、预约、白皮书
    document_name?: '' // 注册文档名称
    video_name?: '' // 注册视频名称
  }
  onSuccess?: () => void // 登录成功回调事件
  onShow?: () => void // 弹窗显示时回调事件
  onHide?: () => void // 弹窗关闭时回调事件
}
const useLoginDialog = ({ config, onSuccess, onShow, onHide, visible = false }: useLoginDialogProps) => {
  if (!isBrowser()) return { show: () => console.log('非浏览器环境，该 hook 只能在浏览器环境使用') }
  if (!SensorsLogin) {
    SensorsLogin = require('@eefe/sensors-login/dist/sensors-login.js')
  }

  useMounted(() => {
    if (visible) {
      show()
    }
  })

  const show = () => {
    const dom = document.createElement('div')
    dom.setAttribute('id', 'login_dialog')
    document.body.appendChild(dom)
    SensorsLogin.renderLoginDialog(
      {
        lang: '', // 语言环境，默认中文简体：zh-CN，繁体：zh-TW
        visible: true, // 在初始化时控制组件显示和隐藏，默认false
        domainPassword: process.env.API_URL, // 登录接口域名配置，可不传，默认：/api/passport，测试环境可配置：/api/_passport
        // domainSchool: 'https://school.sensorsdata.cn',  // 学堂接口域名配置，可不传，默认：https://school.sensorsdata.cn
        config,
        onLoginSuccess: () => {
          allowScroll()
          dom.remove()
          onSuccess && onSuccess()
        },
        onShow: () => {
          forbidScroll()
          onShow && onShow()
        },
        onHide: () => {
          allowScroll()
          dom.remove()
          onHide && onHide()
        },
      },
      dom,
    )
  }

  return { show }
}

export default useLoginDialog
