import React, { useState, useEffect } from 'react'
import { NewDecimalPoint, BackTop, NewTargetSystem, PhoneCall } from '@eefe/gw-components'
import Layout from '../layout'
import Provider, { User } from 'components/Provider'
import FooterRender from './FooterRender'
import HeaderRender from './HeaderRender'
import CookieAuth from 'components/CookieAuth'
import { Languages } from 'i18n/config'
import { isBrowser, isPc } from 'utils/utils'
import * as styles from './index.module.less'
import { useLoginDialogProps } from 'hooks/useLoginDialog'

// 加载登录组件
if (isBrowser()) {
  require('utils/sensors-login/sensors-login.css')
  require('utils/sensors-login/vendors~sensors-login.js')
  require('utils/sensors-login/sensors-login.js')
}

interface LayoutParams {
  className?: string
  footerClassName?: string
  userUpdate?: boolean
  onUserInfo?: (data: User | null) => void
  hideFooter?: boolean
  header?: any
  hideSmallPoint?: boolean
  showLangDropdown?: boolean
  showFloat?: boolean
  showCookieAuth?: boolean
  showPhoneCall?: boolean
  /** 展示 回到最上方 按钮 */
  showBackTop?: boolean
  /** 是否用 h1 标签包裹神策logo，页面如果有其他h1标签建议设置false */
  useH1?: boolean
  /** 登陆组件透传 */
  loginConfig?: useLoginDialogProps // 登陆组件参数
  [propName: string]: any
}

const LayoutZH: React.FC<LayoutParams> = ({
  location,
  children,
  footerClassName,
  userUpdate,
  onUserInfo,
  hideFooter,
  header,
  hideSmallPoint,
  showLangDropdown = false,
  showFloat = true,
  showCookieAuth = true,
  showPhoneCall = true,
  showBackTop = true,
  useH1 = true, // 使用h1标签
  loginConfig = {}, // 登陆组件参数
}) => {
  const [loadPic, setLoadPic] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoadPic(true)
    }, 0)
  }, [])
  return (
    <Provider isUpdate={userUpdate} onUserInfo={onUserInfo} lang={Languages.CN}>
      <Layout location={location}>
        {/* Header */}
        {header ? header : <HeaderRender useH1={useH1} config={loginConfig} />}

        {/* Body */}
        {children}

        {/* Footer */}
        {!hideFooter && <FooterRender />}

        <div className={styles.floatContainer}>
          {/* 小数点 */}
          {loadPic && (
            <div className={`hidden lg:block ${styles.newDecimalPoint}`}>{showFloat && <NewDecimalPoint />}</div>
          )}
          {/* 行业数字化展区 */}
          {loadPic && (
            <div className="hidden lg:block">{showFloat && <NewTargetSystem className={styles.floatLinkBtn} />}</div>
          )}
        </div>
        {showCookieAuth && (
          <CookieAuth
            {...(!isPc() && {
              content: (
                <>
                  我们想使用 cookie 以便更好了解您对本网站的使用情况。这将有助于改善您今后访问本网站的体验。关于 cookie
                  的使用，以及如何撤回或管理您的同意，请详见我们的{' '}
                  <a href="/compliance/privacy.html" target="_blank">
                    《隐私政策》
                  </a>
                  。如您点击确认按钮，将视为您同意 cookie 的使用。
                </>
              ),
            })}
          />
        )}
        {/* 拨打电话 */}
        <div className="lg:hidden">{showPhoneCall && <PhoneCall phoneNumber={4006509827} />}</div>
        {/* 返回顶部 */}
        {showBackTop && <BackTop />}
      </Layout>
    </Provider>
  )
}

export default LayoutZH
