import React, { useContext, useEffect, useState } from 'react'
import { providerContext } from 'components/Provider'
import { fetchLogout } from 'api/user'
import useLoginDialog from 'hooks/useLoginDialog'
import { SensorsHeader } from '@eefe/gw-components/dist/bundle.min.js'
import * as styles from './headerRander.module.less'
import { isEmpty } from 'lodash'

const HeaderRender = ({ config, useH1 = true }) => {
  let { user } = useContext(providerContext)
  const [flag, setFlag] = useState(false)

  const defaultConfig = isEmpty(config)
    ? {
        requestBtn: 'online_guide', // 按钮名
        formpage_source: '导航栏', // 注册表单类型，可选值：视频、demo、文档、预约、白皮书
      }
    : config

  // 登录弹窗hook
  const { show: showLoginDialog } = useLoginDialog({
    config: {
      ...defaultConfig,
    },
    // 登录成功后回调
    onSuccess: () => (window.location.href = '/account/person.html'),
  })

  // 点击头像判断登录
  const handleClickAvatar = () => {
    if (user) {
      // 已登录
      // window.location.href = '/account/person.html';
      // window.location.reload();
    } else {
      // 未登录时
      showLoginDialog()
    }
  }
  // 简体退出登录
  const handleLogout = async () => {
    const res = await fetchLogout()
    const { status } = res.data
    if (status === 200) {
      if (window.location.pathname.indexOf('/account/') >= 0) {
        window.location.href = '/'
      } else {
        window.location.reload()
      }
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setFlag(true)
    }, 0)
  }, [])
  return (
    <>
      {flag && (
        <SensorsHeader
          useH1={useH1}
          className={styles.container}
          isLogin={!!user}
          userInfo={user}
          // NAV_LIST={NAV_LIST}
          handleLogin={handleClickAvatar}
          handleLogout={handleLogout}
        />
      )}
    </>
  )
}

export default HeaderRender
