import * as React from 'react'

const FooterRender = () => {
  const [SensorsFooter, setSensorsFooter] = React.useState<any>(null)

  React.useEffect(() => {
    import('@eefe/gw-components').then((module) => setSensorsFooter(() => module.SensorsFooter))
  }, [])

  if (!SensorsFooter) {
    return <></>
  }

  return <SensorsFooter />
}

export default FooterRender
